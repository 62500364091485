import React from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Trustpilot from '../../components/Trustpilot';
import PromoLogos from '../../components/PromoLogos/PromoLogos';
import TitleImgBlock from '../../components/TitleImgBlock/TitleImgBlock';
import WinATripContent from '../../components/WinATripContent/WinATripContent';
import { baseApiUrl } from '../../configs';

const data = {
  title: 'Win-a-trip',
  seoTitle: 'Win-a-trip | Travelopod',
  seoDescription:
    'Find and book the cheapest flights to any destination at Travelopod! Save money on unique deals for flights, hotels and car rentals online or call us 24/7 for live booking assistance.'
};

class WinATrip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: props.phoneNumber,
      config: {}
    };
  }

  async componentDidMount() {
    // for winATrip config
    const configResponse = await fetch(`${baseApiUrl}/win-a-trip`);
    const configResponseJson = await configResponse.json();
    this.setState({ config: configResponseJson });
  }

  render() {
    const { phoneNumber, config } = this.state;
    const description = data.seoDescription;
    const seotitle = data.seoTitle;

    return (
      <Layout
        isStaticPage
        passPhoneNumber={phoneNumber => {
          this.setState({
            phoneNumber
          });
        }}
      >
        <SEO title={seotitle} description={description} />
        <div className="">
          <TitleImgBlock title="Win Round Trip Flight Tickets" />
          <WinATripContent config={config} />
        </div>
        <Trustpilot />
        <PromoLogos />
      </Layout>
    );
  }
}

export default WinATrip;
